<style lang="scss">
.is-boxed {
  a {
    border: 0px !important;
    border-radius: 0px !important;
  }
}

.tabs {
  .active {
    background: #e8e8e8 !important;

    span {
      color: var(--mdb-dark) !important;
    }
  }

  ul {
    border: 0px !important;
    display: flex;

    li {
      width: 100%;
    }
  }
}
</style>

<template>
  <container-fluid>
    <router-view slot="content"></router-view>
  </container-fluid>
</template>

<script>
import { ContentLoader } from "vue-content-loader"
import { darkModeStyle } from '../../assets/js/darkMode';

export default {
  components: {
    ContentLoader
  },
  data() {
    return {
      darkMode: darkModeStyle('bg-dark text-light', 'bg-light text-dark'),
      darkModeText: darkModeStyle('text-light', 'text-dark'),
      darkModeBtn: darkModeStyle('btn-dark', 'btn-light'),
    }
  }
}
</script>